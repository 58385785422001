<template>
  <div class="modalComponent">
    <div class="modal image_clipper_modal">
      <div class="modal_header">
        <h3 class="title">이미지 자르기</h3>
        <button class="modal_close" @click.prevent="$emit('onclose')"></button>
      </div>
      <div class="modal_body">
        <clipperBasic
          name="clipperB"
          ref="clipper"
          :src="src"
          :ratio="142 / 34"
        ></clipperBasic>
        <clipperPreview name="clipperB"></clipperPreview>
      </div>
      <div class="modal_footer">
        <!-- <button onclick="modalClose('#modal1')">취소</button> -->
        <button @click.prevent="getRrawResult()">
          확인
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { clipperBasic, clipperPreview } from 'vuejs-clipper';
export default {
  mixins: [ModalMixin, DRAG_MODAL_MIXIN],
  props: {
    src: {
      require: true,
    },
  },
  components: {
    clipperBasic,
    clipperPreview,
  },
  methods: {
    getRrawResult() {
      const canvas = this.$refs.clipper.clip();
      //   console.log(canvas.toDataURL());

      const image = canvas.toDataURL();
      const indexOf = image.indexOf('base64,');
      const imageBase64 = image.substr(indexOf + 7);
      this.$emit('onsubmit', imageBase64);
    },
  },
};
</script>

<style scoped></style>
