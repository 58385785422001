<template>
  <div id="contents" class="company_information">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>

      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button
            :class="managementMode ? 'on' : ''"
            @click="toggleManagementMode()"
            class="btn_admin"
          >
            관리
          </button>
          <button
            class="btn_admin"
            :disabled="isValidModify"
            @click="submitForm()"
          >
            저장
          </button>
          <button class="btn_admin" @click="CloseThisPage">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li class="active"><a>회사 정보</a></li>
        </ul>
      </div>
      <div class="article">
        <h5>회사 정보 관리</h5>
        <form action="">
          <div class="form_cont">
            <div>
              <div class="input_text">
                <label>사업자 번호</label>
                <input
                  type="text"
                  placeholder="'-'없이 입력하세요"
                  disabled
                  v-model="editSystemCompany.saup_no"
                />
              </div>
              <div class="input_text">
                <label>로고</label>
                <img
                  width="142px"
                  height="34px"
                  :src="logo_image"
                  class="image"
                  v-if="logo_image != null && !logo_image.includes('null')"
                />
                <clipperUpload
                  v-if="managementMode"
                  v-model="value"
                  class="btn_sub1"
                  style="cursor : pointer;"
                  @input="checkAndOpenModal()"
                  >업로드 이미지</clipperUpload
                >
                <div class="delete_user">
                  <button
                    type="button"
                    @click="deleteLogo()"
                    v-if="managementMode"
                  >
                    <span></span>
                    삭제
                  </button>
                </div>
              </div>
              <image-clipper-modal
                :src="value"
                @onclose="showImageModal = false"
                @onsubmit="
                  $event => {
                    changeLogoImage($event);
                    showImageModal = false;
                  }
                "
                v-if="showImageModal"
              ></image-clipper-modal>

              <!-- <div class="input_text">
                <label>법인번호</label>
                <input
                  type="text"
                  placeholder="'-'없이 입력하세요"
                  :disabled="!managementMode"
                />
              </div> -->
            </div>
            <div>
              <div class="input_text">
                <label>회사명</label>
                <input
                  type="text"
                  placeholder="내용을 입력하세요"
                  v-model="editSystemCompany.name"
                  :disabled="!managementMode"
                />
              </div>
              <div class="input_text">
                <label>대표자명</label>
                <input
                  type="text"
                  placeholder="내용을 입력하세요"
                  v-model="editSystemCompany.representative"
                  :disabled="!managementMode"
                />
              </div>
            </div>
            <div>
              <div class="input_text input_tooltip">
                <label>업태</label>
                <input
                  type="text"
                  placeholder="내용을 입력하세요"
                  v-model="editSystemCompany.business_type"
                  :disabled="!managementMode"
                />
                <span class="tooltip">계산서 발행용</span>
              </div>
            </div>
            <div>
              <div class="input_text input_tooltip">
                <label>종목</label>
                <input
                  type="text"
                  placeholder="내용을 입력하세요"
                  v-model="editSystemCompany.business_item"
                  :disabled="!managementMode"
                />
                <span class="tooltip">계산서 발행용</span>
              </div>
              <div class="input_text input_tooltip">
                <label>대표품목</label>
                <input
                  type="text"
                  placeholder="내용을 입력하세요"
                  v-model="editSystemCompany.representative_item"
                  :disabled="!managementMode"
                />
                <span class="tooltip">계산서 발행용</span>
              </div>
            </div>
            <div class="address">
              <div class="input_text address_search">
                <label>주소</label>
                <input
                  :class="{ active: !managementMode }"
                  type="text"
                  id="postCode"
                  v-model="editSystemCompany.post_number"
                  placeholder="우편번호 입력"
                  disabled
                />
                <button
                  v-show="managementMode"
                  type="button"
                  class="btn_sub2"
                  @click="postSearch"
                >
                  찾기
                </button>
              </div>
              <div class="input_addr input_text">
                <input
                  type="text"
                  id="defaultAddress"
                  v-model="editSystemCompany.address"
                  placeholder="주소 입력"
                  disabled
                />
              </div>
            </div>
            <div class="detail_address input_text">
              <label>상세주소</label>
              <input
                type="text"
                placeholder="상세 주소 입력"
                v-model="editSystemCompany.detail_address"
                :disabled="!managementMode"
              />
            </div>
            <div>
              <div class="input_text">
                <label>계좌번호</label>
                <input
                  type="text"
                  placeholder="'-'없이 입력하세요"
                  :value="editSystemCompany.account_number"
                  @input="
                    $event => {
                      editSystemCompany.account_number = $event.target.value;
                    }
                  "
                  :disabled="!managementMode"
                />
              </div>
              <div>
                <my-local-selectric
                  id="bank-selectric2"
                  :name="'bank-selectric'"
                  :watch="selectedBank"
                  :options="banksOptions"
                  @changeValue="setBank($event)"
                  :state="!managementMode ? 'disabled' : ''"
                ></my-local-selectric>
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>이메일</label>
                <input
                  type="text"
                  placeholder="내용을 입력하세요"
                  :value="editSystemCompany.email"
                  @input="
                    $event => {
                      editSystemCompany.email = $event.target.value;
                    }
                  "
                  :disabled="!managementMode"
                />
              </div>
              <div>
                <my-local-selectric
                  id="email-selectric1"
                  :name="'email-selectric'"
                  :watch="selectedEmail"
                  :options="emailsOptions"
                  @changeValue="setEmail($event)"
                  :state="!managementMode ? 'disabled' : ''"
                ></my-local-selectric>
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>대표번호</label>
                <input
                  type="text"
                  placeholder="내용을 입력하세요"
                  v-model="editSystemCompany.phone_number"
                  :disabled="!managementMode"
                />
              </div>
              <div class="input_text">
                <label>팩스번호</label>
                <input
                  type="text"
                  placeholder="내용을 입력하세요"
                  v-model="editSystemCompany.fax_number"
                  :disabled="!managementMode"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import { mapGetters, mapMutations } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import { clipperUpload } from 'vuejs-clipper';
import FETCH_MIXIN from '@/mixins/fetch';
import ImageClipperModal from '@/layouts/components/ImageClipperModal.vue';
export default {
  mixins: [
    ModalMixin,
    SpinnerMixin,
    ClosePageMixin,
    FavoriteMixin,
    FETCH_MIXIN,
  ],
  components: {
    MyLocalSelectric,
    clipperUpload,
    ImageClipperModal,
  },
  data() {
    return {
      value: null,
      showImageModal: false,
      emailsOptions: [
        {
          label: '직접입력',
          value: '',
        },
        {
          label: '@naver.com',
          value: 'naver.com',
        },
        {
          label: '@gmail.com',
          value: 'gmail.com',
        },
        {
          label: '@hanmail.com',
          value: 'hanmail.com',
        },
        {
          label: '@nate.com',
          value: 'nate.com',
        },
      ],
      banksOptions: [
        {
          label: '은행선택',
          value: '',
        },
        {
          label: '신한은행',
          value: '신한',
        },
        {
          label: '농협은행',
          value: '농협',
        },
        {
          label: '하나은행',
          value: '하나',
        },
        {
          label: '카카오뱅크',
          value: '카카오',
        },
        {
          label: '국민은행',
          value: '국민',
        },
        {
          label: '우리은행',
          value: '우리',
        },
        {
          label: '씨티은행',
          value: '씨티',
        },
        {
          label: 'SC제일은행',
          value: 'SC제일',
        },
        {
          label: '기업은행',
          value: '기업',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromSystemCompanyPage',
      systemCompany: 'getSystemCompany',
      editSystemCompany: 'getEditSystemCompany',
      selectedEmail: 'getSelectedEmailFromEditSystemCompany',
      selectedBank: 'getSelectedBankFromEditSystemCompany',
      logo_image: 'getCompanyLogo',
    }),
    isValidModify() {
      if (this.managementMode) {
        let modifyData = JSON.stringify(this.editSystemCompany);
        let originData = JSON.stringify(this.systemCompany);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return true;
    },
  },
  methods: {
    ...mapMutations({
      toggleManagementMode: 'toggleManagementModeToSystemCompanyPage',
      setPostNumber: 'setEditSystemCompanyPostNumber',
      setAddress: 'setEditSystemCompanyAddress',
    }),
    checkAndOpenModal() {
      if (this.value != null) {
        this.showImageModal = true;
      } else {
        this.openOneButtonModal(
          '업로드 불가',
          '이미지 형식의 파일을 업로드하여주십시오.',
        );
      }
    },
    changeLogoImage(e) {
      this.showSpinner();
      this.$store
        .dispatch('UPDATE_LOGO_IMAGE', {
          saup_no: this.editSystemCompany.saup_no,
          image: e,
        })
        .then(() => {
          this.$store.dispatch('FETCH_LOGO_IMAGE');
        })
        .catch(() => {
          this.openOneButtonModal('수정오류', '로고 수정 중 오류발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    deleteLogo() {
      this.showSpinner();
      this.$store
        .dispatch('DELETE_LOGO_IMAGE')
        .then(() => {
          this.$store.dispatch('FETCH_LOGO_IMAGE');
        })
        .catch(() => {
          this.openOneButtonModal('삭제오류', '로고 삭제 중 오류발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    postSearch() {
      if (this.$screen.width < 1280) {
        this.openOneButtonModal(
          '',
          'Tablet모드에서 지원하지 않는 기능입니다.<br/>PC에서 작업해주세요.',
        );
      } else {
        // let addressData = '';
        // let postNumber = '';
        const setAddress = this.setAddress;
        const setPostNum = this.setPostNumber;
        // eslint-disable-next-line no-undef
        new daum.Postcode({
          oncomplete(data) {
            setAddress(data.address);
            setPostNum(data.zonecode);
            $('#postCode').val(data.zonecode);
            $('#defaultAddress').val(data.address);
          },
        }).open();
      }
    },
    async getMyCompany() {
      //사용자 사업자 정보가 없을 시 새로 받아오기 위한 메소드
      this.showSpinner();
      this.$store
        .dispatch('FETCH_SYSTEM_COMPANY')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '불러오기 오류',
            '사업자 정보를 불러오는 중 오류가 발생하였습니다. 다시 로그인하여주십시오.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },

    setEmail(arg1) {
      // email 상태를 변경하기 위한 메소드
      console.log(arg1);
      this.editSystemCompany.email = this.editSystemCompany.email.split('@')[0];
      this.editSystemCompany.email = this.editSystemCompany.email + '@' + arg1;
    },

    setBank(arg1) {
      // bank 상태를 변경하기 위한 메소드
      this.editSystemCompany.account_number = this.editSystemCompany.account_number.split(
        '/',
      )[0];
      this.editSystemCompany.account_number =
        this.editSystemCompany.account_number + '/' + arg1;
    },

    async submitForm() {
      //수정하기 위한 메소드
      this.showSpinner();
      this.$store
        .dispatch('UPDATE_SYSTEM_COMPANY', this.editSystemCompany)
        .then(() => {
          this.getMyCompany();
          this.toggleManagementMode();
        })
        .catch(() => {
          this.openOneButtonModal('저장 오류', '서버에 문제가 있습니다.');
        });
      this.hideSpinner();
    },
  },

  created() {
    this.FETCH('FETCH_LOGO_IMAGE');
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitSystemCompanyPage');
      this.$route.meta.menu_init = false;
    }
    try {
      if (this.systemCompany.id == '' || this.systemCompany.id == null) {
        this.getMyCompany();
      }
    } catch (error) {
      this.getMyCompany();
    }
  },
};
</script>

<style scoped></style>
